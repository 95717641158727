<template>
    <div class="absolute flex w-screen h-screen top-0 left-0" v-bind:class="{
        'visible opacity-100 z-50': open,
        'invisible opacity-0 -z-1': !open,

        'transition-all duration-300': active,
    }">
        <div class="absolute w-screen h-screen top-0 left-0 bg-black opacity-50 z-1" @click="handleMenuClose"></div>
        <div class="basis-[65vw] max-w-[72.5vw] relative md:basis-1/3 lg:basis-1/4 lg:min-w-[28rem] h-screen bg-white shadow dark:bg-black dark:text-white z-2 transition-transform duration-300 delay-75"
            v-bind:class="{
                '-translate-x-full': !open,
                'translate-x-0': open,
            }">
            <button class="absolute right-4 top-4 cursor-pointer hover:text-primary" @click="handleMenuClose"><ElementsIcon icon="cancel" /></button>
            <div class="flex flex-col justify-between min-h-full">
                <ul class="md:m-8 text-xl md:text-2xl" v-if="user">
                    <li class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink to="/" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="home" size="medium" class="shrink-0" />Dashboard
                        </NuxtLink>
                    </li>
                    <li v-if="!isProducer && !isAdmin"><hr /></li>
                    <LazyDynamicData v-if="isProducer" :getData="() => findOne('businesses', 'current')"
                        dataKey="business-current-new" v-slot="{ data: business, pending: businessPending }">
                        <li v-if="!businessPending && business?.data" class="mx-4 my-8 md:m-8 hover:text-primary">
                            <NuxtLink to="/business/current" @click="handleMenuClose" class="flex gap-4 items-center">
                                <ElementsIcon icon="business" size="medium" class="shrink-0" /> {{ business?.data?.attributes?.name }}
                            </NuxtLink>
                        </li>
                    </LazyDynamicData>
                    <li v-if="!isProducer" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'businesses-slug', params: { slug: '' }, }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="business" size="medium" class="shrink-0" />Produzenten
                        </NuxtLink>
                    </li>
                    <li v-if="!isProducer" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink to="/products" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="product" size="medium" class="shrink-0" />Produkte
                        </NuxtLink>
                    </li>
                    <li v-if="!isProducer" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'certifications-slug'}" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="badge" size="medium" class="shrink-0" />Zertifikationsanträge
                        </NuxtLink>
                    </li>
                    <li v-if="isAdmin"><hr /></li>
                    <li v-if="isAdmin" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'users' }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="users" size="medium" class="shrink-0" />Benutzer
                        </NuxtLink>
                    </li>
                    <li v-if="isAdmin" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'organizations-slug', params: { slug: '' } }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="institute" size="medium" class="shrink-0" /> Organisationen
                        </NuxtLink>
                    </li>
                    <li v-if="isAdmin"><hr /></li>
                    <li v-if="isAdmin" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'configuration-help-texts' }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="messageBubble" size="medium" class="shrink-0" /> Hilfe-Texte
                        </NuxtLink>
                    </li>
                    <li v-if="isAdmin" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'configuration-action-messages' }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="checkmark" size="medium" class="shrink-0" /> Aktionsnachrichten
                        </NuxtLink>
                    </li>
                    <li v-if="isAdmin" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'configuration-marketing-questions' }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="help" size="medium" class="shrink-0" /> Marketingfragen
                        </NuxtLink>
                    </li>
                    <li v-if="isAdmin" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'configuration-templates' }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="document" size="medium" class="shrink-0" /> Dokumentvorlagen
                        </NuxtLink>
                    </li>
                    <li v-if="isAdmin"><hr /></li>
                    <li v-if="isAdmin" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'configuration-import' }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="import" size="medium" class="shrink-0" /> Datenimport
                        </NuxtLink>
                    </li>
                    <li v-if="!isProducer && !isAdmin"><hr /></li>
                    <li v-if="!isProducer" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'export' }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="download" size="medium" class="shrink-0" /> Export
                        </NuxtLink>
                    </li>
                    <li v-if="isProducer" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink to="/business/current/contracts" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="receipt" size="medium" class="shrink-0" />Vereinbarungen
                        </NuxtLink>
                    </li>
                    <li v-if="isProducer" class="mx-4 my-8 md:m-8 text-base">
                        <NuxtLink to="/product/add/business/current"><ElementsButton icon="plus" @click="handleMenuClose">Produkt erstellen</ElementsButton></NuxtLink>
                    </li>
                </ul>
                <ul class="md:m-8 text-2xl" v-if="user">
                    <li v-if="isProducer" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink @click="handleMenuClose(); emit('help');" class="flex gap-4 items-center cursor-pointer">
                            <ElementsIcon icon="help" size="medium" class="shrink-0" />Hilfe
                        </NuxtLink>
                    </li>
                    <li v-if="!isAdmin && !isProducer" class="mx-4 my-8 md:m-8 hover:text-primary">
                        <NuxtLink :to="{ name: 'users' }" @click="handleMenuClose" class="flex gap-4 items-center">
                            <ElementsIcon icon="users" size="medium" class="shrink-0" />Benutzer
                        </NuxtLink>
                    </li>
                </ul>
                <ul class="md:m-8 text-2xl" v-if="!user">
                    <li class="mx-4 my-8 md:m-8 hover:text-primary"><NuxtLink to="/" @click="handleMenuClose" class="flex gap-4 items-center"><ElementsIcon icon="home" size="medium" />Home</NuxtLink></li>
                    <li class="mx-4 my-8 md:m-8">
                        <NuxtLink to="/login"><ElementsButton @click="handleMenuClose">Anmelden</ElementsButton></NuxtLink>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>
<script setup>
const isProducer = useIsProducer()
const isAdmin = useIsAdmin()
const props = defineProps({
    open : { type: Boolean, default: false },
})
const emit = defineEmits(['close', 'help'])

const { findOne } = useStrapi4()
const user = useStrapiUser()

const handleMenuClose = () => {
    open = false
    emit('close')
}

const active = ref(false)
onMounted(function(){
    active.value = true
})
onBeforeUnmount(() => active.value = false )
</script>